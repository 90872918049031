import React, { useState, useEffect } from 'react';

const CarouselWorks = ({ information }) => {
    const [offset, setOffset] = useState(0);
    const initialScrollSpeed = 1.5;
    const [scrollSpeed, setScrollSpeed] = useState(initialScrollSpeed);
    const calibration = (window.innerWidth <= 768 ? 279.5 : 529.5);
    const totalLogoWidth = information.length * calibration;

    const handleMouseEnter = () => {
        setScrollSpeed(0.5);
    };

    const handleMouseLeave = () => {
        setScrollSpeed(initialScrollSpeed);
    };

    // increases the number of tables per screen size
    const getNumberOfBlocks = () => {
        const width = window.innerWidth;
        if (width <= 1023) {
            return 2;
        } else if (width <= 1920) {
            return 3;
        } else if (width <= 2560) {
            return 4;
        } else {
            return 5;
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setOffset((prevOffset) => prevOffset <= -totalLogoWidth ? 0 : prevOffset - scrollSpeed);
        }, 1000 / 40); // 60 FPS

        return () => clearInterval(interval);
    }, [scrollSpeed, totalLogoWidth]);


    return (
        <article className="works" id='works'>
            <section className="title">
                <div>
                    <h3>NOS RÉALISATIONS</h3>
                    <hr />
                </div>
            </section>
            <div
                className="all-block"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {[...Array(getNumberOfBlocks())].map((_, blockIndex) => (
                    <div
                        key={blockIndex}
                        className="block-works"
                        style={{
                            transform: `translateX(${offset}px)`,
                        }}
                    >
                        {information.map((work, i) => (
                            <article className='work__card' key={i}>
                                <img src={work.image.src} alt={work.image.alt} />
                                <div className='work__desc'>
                                    <h4>{work.title}</h4>
                                </div>
                            </article>
                        ))}
                    </div>
                ))}
            </div>
        </article>
    );
};

export default CarouselWorks;