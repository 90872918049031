import { useEffect } from 'react';
import vidange from "../fixture/vidangeur.json";
import Layout from "../components/layout";
import Services from "../components/services";
import Avantages from "../components/avantages";
import Info from "../components/info";
import CarouselWorks from "../components/carouselWorks";
import BannerEco from '../components/bannerEco';
import Contact from '../components/contact';

function Vidangeur() {
  const textBanner = "Intervention rapide 24h/24 en Isère et Savoie"

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout bannerImg={require("../styles/images/background-img-vidange.webp")}>
      <Services info={vidange} />
      <Avantages info={vidange} />
      <CarouselWorks information={vidange.carousel} />
      <Info info={vidange.info} />
      <BannerEco bannerEcoImg={require("../styles/images/banner-eco-background-vidangeur.webp")} text={textBanner} />
      <Contact />
    </Layout>
  );
}

export default Vidangeur;
