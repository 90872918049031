import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Deratiseur from "./pages/deratiseur";
import Vidangeur from "./pages/vidangeur";
import Home from './pages/home';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/3d' exact element={<Deratiseur />} />
          <Route path='/vidangeur' exact element={<Vidangeur />} />
          <Route path='/*' exact element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </>

  );
}

export default App;