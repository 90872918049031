import { useEffect } from "react";
import deratiseur from "../fixture/deratiseur.json";
import Layout from "../components/layout";
import Avantages from "../components/avantages";
import Services from "../components/services";
import CarouselWorks from "../components/carouselWorks";
import Info from "../components/info";
import BannerEco from "../components/bannerEco";
import Contact from "../components/contact";


function Deratiseur() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const textBanner = "Nous nous efforçons d'utiliser des produits d'origine naturelle et des méthodes qui évitent les produits chimiques lorsque cela est possible. Nos interventions commencent par un diagnostic pour identifier les nuisibles concernés, afin d'établir le mode opératoire le plus adapté.";

  return (
    <>
      <Layout
        bannerImg={require("../styles/images/background-img-3d.webp")}
      >
        <Services info={deratiseur} />
        <Avantages info={deratiseur} />
        <CarouselWorks information={deratiseur.carousel} />
        <Info info={deratiseur.info} />
        <BannerEco
          bannerEcoImg={require("../styles/images/banner-eco-background-deratiseur.webp")}
          text={textBanner}
        />
        <Contact />
      </Layout>
    </>
  );
}
export default Deratiseur;
