import React from 'react';

const Info = ({ info }) => {

    return (
        <div className="info">
            <img src={info?.imgSrc} alt={info?.imgAlt} />
            <section className='info-text'>
                <h3>{info?.title}</h3>
                <p>
                    {info.subtitle && <b>{info.subtitle}</b>}
                    {info.desc[0]}
                </p>
                {info.desc[1] &&
                    <div className='info-text__warning'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="CurrentColor" d="M12.5 8.752a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0Z" /><circle cx="11.999" cy="16.736" r=".5" fill="#000000" /><path fill="#000000" d="M18.642 20.934H5.385a2.5 2.5 0 0 1-2.222-3.644L9.792 4.421a2.5 2.5 0 0 1 4.444 0l6.629 12.869a2.5 2.5 0 0 1-2.223 3.644M12.014 4.065a1.478 1.478 0 0 0-1.334.814L4.052 17.748a1.5 1.5 0 0 0 1.333 2.186h13.257a1.5 1.5 0 0 0 1.334-2.186L13.348 4.879a1.478 1.478 0 0 0-1.334-.814" /></svg>
                        <p>{info.desc[1]}</p>
                    </div>
                }
                {info.desc[2] && <p>{info.desc[2]}</p>}

            </section>
        </div>
    )
}

export default Info