import { motion } from "framer-motion";

const Avantages = ({ info }) => {
  return (
    <>
      <section id="avantages">
        <section className="title">
          <div>
            <h3>AVANTAGES</h3>
            <hr />
          </div>
        </section>

        <div className="content">
          <div className="mini-cards">
            {info.avantages.map((avantage, index) => (
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="card"
                key={index}
              >
                <div className="card-img">
                  <img src={avantage.img[0].src} alt={avantage.img[0].alt} />
                </div>
                <div className="text">
                  <h4>{avantage.title}</h4>
                  <p>{avantage.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Avantages;
