import React, { useState, useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');
    const [captchaValue, setCaptchaValue] = useState('');
    const [captchaValid, setCaptchaValid] = useState(false);
    const [isValid, setIsValid] = useState();
    const [isSubmitted, setIsSubmitted] = useState();

    useEffect(() => {
        setCaptchaValid(captchaValue === '5');
    }, [captchaValue]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleNumberChange = (e) => {
        setNumber(e.target.value);
    }

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    }

    const handleCaptchaChange = (e) => {
        setCaptchaValue(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (captchaValid) {
            emailjs.sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                form.current,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
            ).then((result) => {
                console.log(result.text);
                setIsValid(true);
                setIsSubmitted(true);
            }, (error) => {
                console.error(error.text);
            });
            form.current.reset();
            setEmail('');
            setName('');
            setMessage('');
            setNumber('');
            console.log('Formulaire envoyé !');
        } else {
            alert('Veuillez résoudre le captcha correctement !');
        }
    }

    const blockDesc = [
        {
            icone: "<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'><path fill='CurrentColor' d='M9 13h2v-2.75h2V13h2V8.25l-3-2l-3 2zm3 6.35q3.05-2.8 4.525-5.087T18 10.2q0-2.725-1.737-4.462T12 4Q9.475 4 7.738 5.738T6 10.2q0 1.775 1.475 4.063T12 19.35M12 22q-4.025-3.425-6.012-6.362T4 10.2q0-3.75 2.413-5.975T12 2q3.175 0 5.588 2.225T20 10.2q0 2.5-1.987 5.438T12 22m0-12' /></svg>",
            desc: "Nous sommes basés au coeur da la vallée du Grésivaudan, entre Grenoble et Chambéry. Notre entreprise à plusieurs activités dans la 3D (dératisation, désinsectisation, désinfection), elle est certifiée par le ministère de l'environnement pour les traitements biocide et phytosanitaire. Nous sommes également référencés au Groupement de Défense Sanitaire (GDS) de l'Isère et de la Savoie pour la destruction des nids de frelons Asiatiques."
        },
        {
            icone: "<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 256 256'><path fill='CurrentColor' d='M248 128a56 56 0 1 0-96 39.14V224a8 8 0 0 0 11.58 7.16L192 216.94l28.42 14.22A8 8 0 0 0 232 224v-56.86A55.81 55.81 0 0 0 248 128m-56-40a40 40 0 1 1-40 40a40 40 0 0 1 40-40m3.58 112.84a8 8 0 0 0-7.16 0L168 211.06v-32.47a55.94 55.94 0 0 0 48 0v32.47ZM136 192a8 8 0 0 1-8 8H40a16 16 0 0 1-16-16V56a16 16 0 0 1 16-16h176a16 16 0 0 1 16 16a8 8 0 0 1-16 0H40v128h88a8 8 0 0 1 8 8m-16-56a8 8 0 0 1-8 8H72a8 8 0 0 1 0-16h40a8 8 0 0 1 8 8m0-32a8 8 0 0 1-8 8H72a8 8 0 0 1 0-16h40a8 8 0 0 1 8 8'/></svg>",
            desc: "Nous sommes agrémentés par la Préfecture pour les transports de matières de vidange. Nous utilisons de multiples techniques et matériels spécifiques qui permettent d'intervenir dans des environnements contraints et en situation d’urgence."
        },
        {
            icone: "<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'><path fill='CurrentColor' d='M.75 7.5h9.75l.75 1.5H1.5zm1 3h9.75l.75 1.5H2.5zm16.25 8c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5m1.5-9H17V12h4.46zM8 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5M20 8l3 4v5h-2c0 1.66-1.34 3-3 3s-3-1.34-3-3h-4c0 1.66-1.35 3-3 3c-1.66 0-3-1.34-3-3H3v-3.5h2V15h.76c.55-.61 1.35-1 2.24-1c.89 0 1.69.39 2.24 1H15V6H3c0-1.11.89-2 2-2h12v4z'/></svg>",
            desc: "Nous intervenons aussi bien chez les particuliers, que pour les collectivités ou les entreprises."
        }
    ]

    return (
        <section className="contact" id="contact">
            <section className="title">
                <div>
                    <h3>Nous Contacter</h3>
                    <hr />
                </div>
            </section>

            <div className="contact-content">
                <div className='description'>
                    <div className='border'>
                        {
                            blockDesc.map((block, i) => (
                                <article className='description__block' key={i}>
                                    <div dangerouslySetInnerHTML={{ __html: block.icone }} />
                                    <p>{block.desc}</p>
                                </article>
                            ))
                        }
                    </div>
                </div>
                <form ref={form} onSubmit={handleSubmit}>
                    <input type="email" name="from_email" placeholder="E-mail" value={email} onChange={handleEmailChange} required />
                    <input type="text" name="to_name" placeholder="Nom / Prénom" value={name} onChange={handleNameChange} required />
                    <input type="tel" name="from_number" placeholder="Numéro de téléphone" value={number} onChange={handleNumberChange} required />
                    <textarea cols="30" rows="10" placeholder="Message..." name="message" value={message} onChange={handleMessageChange} required />
                    <input type="text" placeholder="4 + 1 = ?" onChange={handleCaptchaChange} required />
                    <div className={`submit-button ${isValid ? "valid" : ""}`}>
                        <button type="submit" disabled={isSubmitted}>
                            <p>ENVOYER</p>
                            <svg className="send" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path d="M452.1 49L52.3 265.3c-6 3.3-5.6 12.1.6 14.9l68.2 25.7c4 1.5 7.2 4.5 9 8.4l53 109.1c1 4.8 9.9 6.1 10 1.2l-8.1-90.2c.5-6.7 3-13 7.3-18.2l207.3-203.1c1.2-1.2 2.9-1.6 4.5-1.3 3.4.8 4.8 4.9 2.6 7.6L228 338c-4 6-6 11-7 18l-10.7 77.9c.9 6.8 6.2 9.4 10.5 3.3l38.5-45.2c2.6-3.7 7.7-4.5 11.3-1.9l99.2 72.3c4.7 3.5 11.4.9 12.6-4.9L463.8 58c1.5-6.8-5.6-12.3-11.7-9z" fill="currentColor" /></svg>
                            <svg className="valid-send" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m.5 8.55l2.73 3.51a1 1 0 0 0 .78.39a1 1 0 0 0 .78-.36L13.5 1.55" /></svg>
                        </button>
                        <p className="submit-text">Votre message à bien était envoyer, nous vous recontacterons dans les plus brefs délais !</p>
                    </div>
                </form >
            </div>
        </section>
    );
};

export default Contact;