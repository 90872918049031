import React, { useRef, useEffect, useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import { NavLink, useLocation } from "react-router-dom";

const Layout = ({ children, bannerImg }) => {
  const menuRef = useRef(null);
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);

  const menuToggle = () => {
    menuRef.current.classList.toggle('active__menu');
    document.body.classList.toggle('modal-open')
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }

    menuRef.current.classList.remove('active__menu');
    document.body.classList.remove('modal-open');
  };

  const handleNavClick = (sectionId) => {
    if (sectionId === "3D" && location.pathname === "/3D") {
      scrollToSection("header");
    }
    if (sectionId === "vidangeur" && location.pathname === "/vidangeur") {
      scrollToSection("header");
    }
  };

  const bannerStyle = {
    backgroundImage: ` url(${bannerImg})`
  }

  const AnimatedHeading = () => {
    const styles = useSpring({
      from: { opacity: 0, transform: "translateX(100%)" },
      to: { opacity: 1, transform: "translateX(0)" },
      config: { duration: 1000 },
    });

    return (
      <animated.h2 className="animated-heading" style={styles}>
        D'ONOFRIO 3D
      </animated.h2>
    );
  };

  useEffect(() => {
    if (modalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    const hasModalBeenShown = localStorage.getItem("hasModalBeenShown");
    if (!hasModalBeenShown) {
      setModalOpen(true);
      localStorage.setItem("hasModalBeenShown", true);
    }
  }, [modalOpen]);

  return (
    <>
      {modalOpen && (<div className="modal"></div>)}
      <header style={bannerStyle} id="header">
        <nav>
          <NavLink onClick={() => scrollToSection("header")}>
            <h1>
              <img className="logo" alt="logo" src="./logo_rond.png"></img>
            </h1>
          </NavLink>
          <div ref={menuRef}>
            <ul>
              <li><NavLink to="/home">Accueil</NavLink></li>
              <li><NavLink to="/3D" onClick={() => handleNavClick("3D")}>Nuisible 3D</NavLink></li>
              <li><NavLink to="/vidangeur" onClick={() => handleNavClick("vidangeur")}>Assainissement</NavLink></li>
              <li onClick={() => scrollToSection("contact")}>Nous Contacter</li>
            </ul>
          </div>
          <div className="nav-mobile" onClick={menuToggle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="CurrentColor" d="M4 18q-.425 0-.712-.288T3 17q0-.425.288-.712T4 16h16q.425 0 .713.288T21 17q0 .425-.288.713T20 18zm0-5q-.425 0-.712-.288T3 12q0-.425.288-.712T4 11h16q.425 0 .713.288T21 12q0 .425-.288.713T20 13zm0-5q-.425 0-.712-.288T3 7q0-.425.288-.712T4 6h16q.425 0 .713.288T21 7q0 .425-.288.713T20 8z" /></svg>
          </div>
        </nav>

        <section className="home">
          <div className="home-block">
            <AnimatedHeading />
            <p>Intervention rapide 24h/24 en Isère et Savoie</p>
            <p>06 28 94 13 74</p>
            <b>DEVIS GRATUIT</b>
            <div className="button-home">
              <button onClick={() => scrollToSection("contact")} className="button">
                NOUS CONTACTER
              </button>
            </div>
          </div>
        </section>
      </header>

      <main>{children}</main>

      <footer>
        <div className="footer-top">
          <ul className="infos">
            <li>
              <h3>Nous concernant</h3>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 48 48"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="4"
                >
                  <circle cx="24" cy="11" r="7" />
                  <path d="M4 41c0-8.837 8.059-16 18-16m9 17l10-10l-4-4l-10 10v4z" />
                </g>
              </svg>
              <p>David D'onofrio</p>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 12q.825 0 1.413-.587T14 10q0-.825-.587-1.412T12 8q-.825 0-1.412.588T10 10q0 .825.588 1.413T12 12m0 7.35q3.05-2.8 4.525-5.087T18 10.2q0-2.725-1.737-4.462T12 4Q9.475 4 7.738 5.738T6 10.2q0 1.775 1.475 4.063T12 19.35M12 22q-4.025-3.425-6.012-6.362T4 10.2q0-3.75 2.413-5.975T12 2q3.175 0 5.588 2.225T20 10.2q0 2.5-1.987 5.438T12 22m0-12"
                />
              </svg>
              <p>19 Rue de la Noue 38660 LE TOUVET</p>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M16 11V8h-3V6h3V3h2v3h3v2h-3v3zm3.95 10q-3.125 0-6.187-1.35T8.2 15.8q-2.5-2.5-3.85-5.55T3 4.05V3h5.9l.925 5.025l-2.85 2.875q.55.975 1.225 1.85t1.45 1.625q.725.725 1.588 1.388T13.1 17l2.9-2.9l5 1.025V21z"
                />
              </svg>
              <p>06 28 94 13 74</p>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m4 6l8 5l8-5zm0 14q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v5q0 .425-.288.713T21 12q-.425 0-.712-.288T20 11V8l-7.475 4.675q-.075.05-.525.15q-.125 0-.262-.037t-.263-.113L4 8v10h5.5q.425 0 .713.288T10.5 19q0 .425-.288.713T9.5 20zm11.95-.8l4.95-4.95q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-5.65 5.65q-.3.3-.7.3t-.7-.3l-2.85-2.85q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275z"
                />
              </svg>
              <p>contact@donofrio3d.fr</p>
            </li>
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" d="M18.5 5.5h2v18h-17v-20c8.5-1 14.75-3 14.75-3h.25zm0 0H6m10.5 9A4.5 4.5 0 0 0 12 10m4.5 4.5A4.5 4.5 0 0 1 12 19m4.5-4.5c-.5.5-2 1-4.5 1s-4-.5-4.5-1M12 10a4.5 4.5 0 0 0-4.5 4.5M12 10c.966 0 1.75 2.015 1.75 4.5S12.966 19 12 19m0-9c-.966 0-1.75 2.015-1.75 4.5S11.034 19 12 19m-4.5-4.5A4.5 4.5 0 0 0 12 19" /></svg>
              <p>S.I.R.E.T: 510 094 618 00020</p>
            </li>
          </ul>

          <ul className="infos">
            <li>
              <h3>Nos services</h3>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M11 13H5v-2h6V5h2v6h6v2h-6v6h-2z"
                />
              </svg>
              <NavLink to="/">3D</NavLink>

            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M11 13H5v-2h6V5h2v6h6v2h-6v6h-2z"
                />
              </svg>
              <NavLink to="/vidangeur">Vidangeurs / Hydrocurage</NavLink>
            </li>
          </ul>

          <ul className="infos">
            <li>
              <h3>Suivez-nous</h3>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"
                  fill="currentColor"
                />
              </svg>
            <a href="https://www.facebook.com/profile.php?id=61559835775224&locale=fr_FR" target="_blank" rel="noreferrer">Facebook</a>
            </li>
            {/* <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"
                />
              </svg>
              <NavLink to="">Insta</NavLink>
            </li> */}
          </ul>
        </div>
        <div className="footer-bottom">
          <section className="mentions">
            <p>TOUS DROITS RÉSERVÉS </p>
          </section>
          <p className="copyright">
            <NavLink to="https://www.jv-design-et-tech.fr/" target="_blank">
              Site réalisé par JV design & tech - COPYRIGHT © 2024
            </NavLink>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Layout;
