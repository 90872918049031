import { NavLink } from "react-router-dom";
import React, { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    const isModalOpen = document.body.classList.contains('modal-open');
    if (isModalOpen) {
      document.body.classList.remove('modal-open');
    }
  }, [])

  return (
    <div className="home-page">
      <div className="home trois-d" >
        <article className="home-menu">
          <div className="menu">
            <h2>Nuisible (3D)</h2>
            <p>DÉRATISATION</p>
            <p>DÉSINSECTISATION</p>
            <p>DÉSINFECTION</p>
          </div>
        </article>
      </div>
      <NavLink to="/3d" className="left">
      </NavLink>
      <div className="logo">
        <section className="contact-accueil">
          <h2>Intervention rapide 24h/24 en Isère et Savoie</h2>
          <p> 06 28 94 13 74</p>
          <p>DEVIS GRATUIT</p>
        </section>
        <img src="logo_rond.png" alt="" />
      </div>

      <div className="home curage">
        <article className="home-menu">
          <div className="menu">
            <h2>Assainissement</h2>
            <p>HYDROCURAGE / CURAGE</p>
            <p>VIDANGE</p>
            <p>DÉBOUCHAGE D’URGENCE </p>
            <p>INSPÉCTION CAMERA </p>
          </div>
        </article>
      </div>
      <NavLink to="/vidangeur" className="right">
      </NavLink>
    </div>
  );
};

export default Home;
